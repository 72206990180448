import {MouseEvent, useEffect, useState} from "react";
import {GridRowId, GridSortModel} from "@mui/x-data-grid";
import {useReactiveVar} from "@apollo/client";
import {
    equipmentRequestListPaginationVar,
    useEquipmentRequestListPaginationVar
} from "store/reactiveVarsStores/equipment-request/equipmentRequestListPagination";
import {
    EquipmentRequestsFilters,
    equipmentRequestsFiltersVar
} from "store/reactiveVarsStores/equipment-request/filtersVar";
import {
    EquipmentRequestListSortOrder,
    equipmentRequestListSortOrderVar,
    useEquipmentRequestListSortOrderVar
} from "store/reactiveVarsStores/equipment-request/equipmentRequestListSortOrder";
import {
    CombinedEquipmentRequestEntity,
    EquipmentRequestEntity,
    GetEquipmentRequestsInput,
    useGetEquipmentRequestsQuery
} from "graphql/graphQlApiHooks";
import Settings from "store/settings";
import {sportObjectsVars} from "store/reactiveVarsStores/sport-objects/sportObjects";
import {getCombinedAndSingleRequests} from "../lib/getCombinedAndSingleRequests";
import {RequestEntity} from "../types/RequestEntity";


interface UseEquipmentRequestTableReturn {
    rows: RequestEntity[];
    loading: boolean;
    allCount: number;
    count: number;
    selected: GridRowId[];
    handleSelectionChange: (newSelection: GridRowId[]) => void;
    contextMenuHandler: (event: MouseEvent) => void;
    contextMenuCloseHandler: () => void;
    handleSortModelChange: (model: GridSortModel) => void;
    handlePageChange: (page: number) => void;
    handlePageSizeChange: (pageSize: number) => void;
    refetchData: () => void;
    contextRow: RequestEntity | null;
    contextMenu: { mouseX: number; mouseY: number } | null;
    pageSize: number;
    page: number;
    combinedRequestsLength: number;
}

export const useEquipmentRequestTable = (): UseEquipmentRequestTableReturn => {
    const [firstLoad, setFirstLoad] = useState(true);
    const [rows, setRows] = useState<RequestEntity[]>([]);
    const [combinedRequestsLength, setCombinedRequestsLength] = useState(0);
    const [allCount, setAllCount] = useState(0);
    const [count, setCount] = useState(0);
    const [selected, setSelected] = useState<GridRowId[]>([]);
    const [contextRow, setContextRow] = useState<RequestEntity | null>(null);
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number } | null>(null);

    const pagination = useReactiveVar(equipmentRequestListPaginationVar);
    const filters = useReactiveVar(equipmentRequestsFiltersVar);
    const sortOrderModel = useReactiveVar(equipmentRequestListSortOrderVar);

    const {setPagination} = useEquipmentRequestListPaginationVar();
    const {setOrder} = useEquipmentRequestListSortOrderVar();

    const {selectedSportObjectIds, filteredSportZoneList, filteredSportSubZoneList} = useReactiveVar(sportObjectsVars);

    const getFilters = (equipmentRequestsFilters: EquipmentRequestsFilters) => {
        const {applicationDate, ...filters} = equipmentRequestsFilters;
        return {
            ...filters,
            ...(applicationDate && {
                dateFrom: applicationDate?.startDate?.toISOString(),
                dateTo: applicationDate?.endDate?.toISOString(),
            })
        };
    };

    const {data, loading, refetch} = useGetEquipmentRequestsQuery({
        variables: {
            input: {
                ...getFilters(filters),
                sportObjectIds: selectedSportObjectIds,
                skip: pagination?.page * pagination?.pageSize,
                take: pagination?.pageSize,
                order: sortOrderModel,
            } as GetEquipmentRequestsInput,
        },
        skip: !selectedSportObjectIds?.length || selectedSportObjectIds?.length === 0,
        pollInterval: Settings.pollIntervals.sportEquipments,
        fetchPolicy: "cache-and-network",
        refetchWritePolicy: "overwrite",
        errorPolicy: 'ignore',
    });

    useEffect(() => {
        const combineEquipments = data?.getEquipmentRequests?.combinedEquipmentRequests as CombinedEquipmentRequestEntity[] || []
        const singleEquipments = data?.getEquipmentRequests?.equipmentRequests as EquipmentRequestEntity[] || []
        const combinedAndSingleEquipments = getCombinedAndSingleRequests(singleEquipments, combineEquipments);

        setRows(combinedAndSingleEquipments);
        setCombinedRequestsLength(combineEquipments?.length);
        setAllCount(Math.round(data?.getEquipmentRequests?.allCount) || 0);
        setCount(data?.getEquipmentRequests?.count || 0);
        setFirstLoad(false);
    }, [data]);

    const refetchData = () => {
        refetch({
            input: {
                ...getFilters(filters),
                sportObjectIds: selectedSportObjectIds,
                skip: pagination?.page * pagination?.pageSize,
                take: pagination?.pageSize,
                order: sortOrderModel,
            } as GetEquipmentRequestsInput,
        });
    };

    useEffect(() => {
        if (!firstLoad) refetchData();
    }, [pagination, filters, selectedSportObjectIds, sortOrderModel, firstLoad]);



    const handleSortModelChange = (model: GridSortModel) => {
        const sort = model.map((item) => ({
            [item.field]: item.sort.toUpperCase(),
        } as EquipmentRequestListSortOrder));
        setOrder(sort[0]);
    };

    const handleSelectionChange = (newSelection: GridRowId[]) => {
        setSelected(newSelection);
    };

    const handlePageChange = (page: number) => {
        setPagination({...pagination, page});
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({...pagination, pageSize});
    };

    const contextMenuHandler = (event: MouseEvent) => {
        event.preventDefault();
        const row = event.currentTarget;
        const equipmentRequestId = row.getAttribute("data-id");
        setContextRow(rows.find((item) => item.id === equipmentRequestId) || null);
        setContextMenu(
            contextMenu === null
                ? {mouseX: event.clientX + 2, mouseY: event.clientY - 6}
                : null
        );
    };

    const contextMenuCloseHandler = () => {
        setContextMenu(null);
    };

    return {
        rows,
        loading,
        allCount,
        selected,
        handleSelectionChange,
        contextMenuHandler,
        contextMenuCloseHandler,
        handleSortModelChange,
        handlePageChange,
        handlePageSizeChange,
        refetchData,
        contextRow,
        contextMenu,
        pageSize: pagination?.pageSize,
        page: pagination?.page,
        count,
        combinedRequestsLength
    };
};

