import React, {FC, useState} from "react";
import {useEquipmentRequestsAcceptForProcessingMutation} from "graphql/graphQlApiHooks";
import {Alert, Button, DialogActions, DialogContent, TextField} from "@mui/material";
import {declOfNum} from "helpers/declination";
import {LoadingButton} from "@mui/lab";

interface Props {
    close: () => void;
    equipmentIds: string[];
}

export const MultiplySuccessRequestsDialog: FC<Props> = (rest) => {
    const { close, equipmentIds } = rest
    const [message, setMessage] = useState("")
    const [equipmentRequestsAcceptForProcessing, { loading }] = useEquipmentRequestsAcceptForProcessingMutation({
        refetchQueries: ["getEquipmentRequests"],
        awaitRefetchQueries: true
    })

    const handleSubmit = () => {
        equipmentRequestsAcceptForProcessing({
            variables: {
                input: {
                    equipmentRequestIds: equipmentIds,
                    message: message,
                },
            },
        }).then(() => {
            close()
        })
    }

    return (
        <>
            <DialogContent>
                <Alert severity="info" sx={{ mb: 2 }}>
                    <>
                        {declOfNum(equipmentIds.length, ["Выбрана", "Выбрано", "Выбрано"])} <b>{equipmentIds.length}</b>{" "}
                        {declOfNum(equipmentIds.length, ["единица", "единицы", "единиц"])} оборудования, для приема в работу. <br />
                    </>
                </Alert>

                <TextField
                    fullWidth
                    label="Сообщение"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Введите сообщение"
                    variant="outlined"
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="secondary" size="small">
                    Отмена
                </Button>

                <LoadingButton size="small" onClick={() => handleSubmit()} variant="contained" loading={loading}>
                    Принять в работу
                </LoadingButton>
            </DialogActions>
        </>
    )
}

