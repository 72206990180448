import React, { FC, useEffect, useState } from "react";
import { Chip as MuiChip, chipClasses, Dialog, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SportEquipmentEntity, useGetSportEquipmentByIdQuery } from "graphql/graphQlApiHooks";
import SportEquipmentPreloader from "./Preloader";
import { lighten, styled, Theme } from "@mui/material/styles";
import SportEquipmentCard from "./SportEquipmentCard/imdex";
import { Helmet } from "react-helmet";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import { SportEquipmentWriteOffDialog } from "../WriteOff";

export const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => (prop !== "state" && prop !== "type")
})<{
  state: boolean;
  type?: string;
}>(({ theme, state, type }) => {
  const color = type === "technicalCondition" ? state ? theme.palette.success.main : theme.palette.error.main : state ? theme.palette.primary.main : theme.palette.grey[500];
  return {
    [`&.${chipClasses.root}`]: {
      backgroundColor: lighten(color, .8),
      height: "auto",
      padding: theme.spacing(.7, 1.5),
      borderRadius: theme.shape.borderRadius
    },
    [`& .${chipClasses.label}`]: {
      color: color,
      fontSize: "12px",
      lineHeight: "12px",
      padding: 0
    },
    [`& .${chipClasses.icon}`]: {
      color: color,
      fontSize: "6px",
      marginRight: "4px",
      marginLeft: "0"
    }
  };
});

interface Props {
  dialogProps?: DialogProps;
}


export const SportEquipment: FC<Props> = ({dialogProps}:Props) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { sportEquipmentId: id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [sportEquipment, setSportEquipment] = useState<SportEquipmentEntity | null>(null);

  const { data, error } = useGetSportEquipmentByIdQuery({
    variables: {
      id
    },
    skip: !id,
    fetchPolicy: "cache-and-network"
  });

  if (error?.message) {
    enqueueSnackbar(error?.message, {
      variant: "error"
    });
  }


  useEffect(() => {
    let getSportEquipmentById = data?.getSportEquipmentById as SportEquipmentEntity;

    if (getSportEquipmentById && !sportEquipment) {
      setSportEquipment(getSportEquipmentById);

      if (loading) setLoading(false);
    }
  }, [data?.getSportEquipmentById, loading, sportEquipment]);

  const onClose = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      const path = location.pathname.split("/").slice(0, -1).join("/");
      navigate(path);
    }
  };


  return (
    <Dialog
      onClose={onClose}
      maxWidth={"md"}
      fullWidth
      open={true}
      PaperProps={{
        sx: {
          height: sm ? "100%" : 550,
          width: "100%"
        }
      }}
      {...dialogProps}
    >
      <Helmet>
        <title>
          {
            sportEquipment?.name ? `Просмотр спортивного оборудования | ${sportEquipment?.name}` : "Просмотр спортивного оборудования"
          }
        </title>
      </Helmet>

      {/*
        Если спортивное оборудование не загружено, то показываем прелоадер
      */}
      {!sportEquipment ? (
        <SportEquipmentPreloader />
      ) : (
        // Иначе показываем карточку спортивного оборудования
        <SportEquipmentCard
          sportEquipment={sportEquipment}
          onClose={onClose}
        />
      )}
      <SportEquipmentWriteOffDialog />
    </Dialog>

  );


};


export default SportEquipment;