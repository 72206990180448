import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Fade,
    InputAdornment,
    LinearProgress,
    Stack,
    tableClasses,
    TextField,
    Typography
} from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import {
    CombinedEquipmentRequestEntity,
    EquipmentRequestEntity,
    useGetEquipmentRequestsQuery,
    useUpdateCombinedEquipmentRequestMutation
} from "graphql/graphQlApiHooks";
import {Helmet} from "react-helmet";
import {useTheme} from "@mui/styles";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useReactiveVar} from "@apollo/client";
import {sportObjectsVars} from "store/reactiveVarsStores/sport-objects/sportObjects";
import Iconify from "components/Iconify";
import {declOfNum} from "helpers/declination";
import Tooltiper from "components/Tooltiper";
import {Tooltip} from "../ui/Tooltip";
import DateViewer from "components/DateViewer";
import {useViewCombineRequest} from "../../hooks/actions/useViewCombineRequest";
import {PriceCellRenderer} from "../EquipmentRequestTable/components/RenderCells/PriceCellRenderer";
import {RequestStatusCellRenderer} from "../EquipmentRequestTable/components/RenderCells/RequestStatusCellRenderer";
import {RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined} from "@mui/icons-material";

interface Props {
    equipments: EquipmentRequestEntity[];
    onClose: () => void;
}


export const AddToCombinedEquipmentRequestDialog: FC<Props> = (props) => {
    const {equipments, onClose} = props;
    const {handleViewCombineRequest} = useViewCombineRequest();
    const theme = useTheme();
    const [rows, setRows] = useState<Array<CombinedEquipmentRequestEntity>>([]);
    const [selectedRow, setSelectedRow] = useState<string | null>(null);
    const [updateCombinedEquipmentRequest] = useUpdateCombinedEquipmentRequestMutation({
        refetchQueries: ['getEquipmentRequests']
    });
    const {selectedSportObjectIds} = useReactiveVar(sportObjectsVars);


    const {data, loading} = useGetEquipmentRequestsQuery({
        variables: {
            input: {
                sportObjectIds: selectedSportObjectIds
            }
        },
        errorPolicy: 'ignore',
        skip: selectedSportObjectIds?.length === 0,
    });

    useEffect(() => {
        setRows(data?.getEquipmentRequests?.combinedEquipmentRequests as CombinedEquipmentRequestEntity[] || []);
    }, [data?.getEquipmentRequests]);

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filteredEquipments = rows.filter((equipment) =>
        equipment.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Наименование',
            minWidth: 200,
            flex: 1,
            renderCell: ({row}) => (
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} gap={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                            width: '100%',
                            minWidth: 0
                        }}
                    >
                        {
                            row.id !== selectedRow ? <RadioButtonUncheckedOutlined sx={{color: 'primary.main'}}/> :
                                <RadioButtonCheckedOutlined sx={{color: 'primary.main'}}/>
                        }

                        {(row?.urgentRequirementComment) &&
                            <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 300, exit: false}}
                                disableFocusListener
                                title={
                                    <Stack direction={'row'} spacing={2}>
                                        <Box display={'flex'}>
                                            <Iconify
                                                icon="line-md:alert-twotone"
                                                sx={{
                                                    fontSize: 20,
                                                    color: 'error.main',
                                                }}
                                            />
                                        </Box>
                                        <Stack spacing={1}>
                                            <Typography
                                                sx={{whiteSpace: 'nowrap'}}
                                                variant={'caption'}
                                                color={'error.main'}
                                            >
                                                Срочная заявка!
                                            </Typography>
                                            <Box
                                                sx={{
                                                    textOverflow: "ellipsis",
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                {row?.urgentRequirementComment}
                                            </Box>
                                        </Stack>
                                    </Stack>
                                }
                            >
                                <Box display={'flex'}>
                                    <Iconify
                                        icon="cuida:alert-outline"
                                        sx={{
                                            fontSize: 21,
                                            color: 'error.main',
                                            mr: -1
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        }
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'start'}
                            width={'100%'}
                            gap={0}
                            minWidth={0}
                        >
                            <Tooltiper
                                text={row?.name}
                                props={{
                                    onClick: () => {
                                        handleViewCombineRequest(row);
                                    },
                                    variant: 'subtitle1',
                                    sx: {
                                        mb: 0,
                                        transition: 'color 200ms ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: 'primary.main'
                                        }
                                    },
                                }}
                            />
                            <Tooltiper
                                text={`количество заявок: ${row?.childRequests?.length}`}
                                props={{
                                    sx: {
                                        fontSize: '7px',
                                        color: 'text.secondary',
                                        mt: -1
                                    }
                                }}
                            />

                        </Box>
                    </Box>
                </Box>

            ),
            disableColumnMenu: true,
        },
        {
            field: 'price',
            headerName: 'Стоимость',
            minWidth: 150,
            renderCell: ({row}) => <PriceCellRenderer equipmentRequestEntity={row}/>,
            disableColumnMenu: true,
        },
        {
            field: 'requestStatus',
            headerName: 'Статус',
            minWidth: 200,
            disableColumnMenu: true,
            renderCell: ({row}) => <RequestStatusCellRenderer disableEdit={true} equipmentRequestEntity={row}/>,
        },
        {
            field: 'createdAt',
            headerName: 'Дата создания',
            minWidth: 200,
            type: 'dateTime',
            align: 'right',
            headerAlign: 'right',
            disableColumnMenu: true,
            renderCell: ({row}) => <Box color={'grey.600'} textAlign={'right'}>
                <DateViewer showIcon={false} showTime={true} date={row?.createdAt}/>
            </Box>
        },
    ];

    return (
        <>
            <Helmet>
                <title>
                    Добавление заявок в объединённую
                </title>
            </Helmet>

            <DialogContent dividers>
                <TextField
                    sx={{
                        mt: 0,
                        mb: 4
                    }}
                    label="Поиск по заявкам"
                    variant="standard"
                    inputProps={{
                        'aria-label': 'Поиск по заявкам',
                    }}
                    size={'medium'}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                            </InputAdornment>
                        )
                    }}
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />

                <DataGrid
                    className={tableClasses.root}
                    sx={{
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 1,
                    }}
                    loading={loading}
                    rows={filteredEquipments}
                    columns={columns}
                    autoHeight
                    components={{
                        Footer: () => null,
                        LoadingOverlay: LinearProgress,
                        NoResultsOverlay: () => {
                            return <Box sx={{
                                display: "flex",
                                bgcolor: "background.default",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%"
                            }}/>;
                        },
                        NoRowsOverlay: () => {
                            return <Box sx={{
                                display: "flex",
                                bgcolor: "background.default",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%"
                            }}/>;
                        }

                    }}
                    getRowClassName={(params) =>
                        params.row.parentId === selectedRow ? 'parent-row-highlight' : ''
                    }
                    onSelectionModelChange={(selectionModel) => {
                        const selectedId = selectionModel[0]?.toString() || null;
                        setSelectedRow(selectedId);
                    }}
                />
            </DialogContent>

            <DialogActions>
                {rows.find(row => row.id === selectedRow)?.name &&
                    <Typography variant={'body2'} mr={'auto'} px={3}>
                        Хотите добавить&nbsp;
                        {declOfNum(equipments.length, ['выбранную', 'выбранные', 'выбранные'])}&nbsp;
                        {equipments.length > 1 ? 'заявки' : 'заявку'}
                        &nbsp;в&nbsp;
                        <b>{rows.find(row => row.id === selectedRow)?.name}</b>?
                    </Typography>
                }
                <Button
                    onClick={onClose}
                    color="secondary"
                >
                    Отмена
                </Button>
                <Button
                    onClick={() => {
                        const combined = rows.find(row => row.id === selectedRow) as CombinedEquipmentRequestEntity;
                        updateCombinedEquipmentRequest({
                            variables: {
                                input: {
                                    id: selectedRow,
                                    childRequestId: [...combined.childRequests.map(item => item.id), ...equipments.map(item => item.id)]
                                }
                            }
                        })
                        onClose();
                    }}
                    color="primary"
                    disabled={!selectedRow}
                >
                    Добавить
                </Button>
            </DialogActions>
        </>
    );
};
